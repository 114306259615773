.main {
  padding: 0px 90px;
  font-family: Roboto;
  font-style: normal;
}

.path {
  margin: 54px 0px;
  letter-spacing: 0.05em;
  font-size: 16px;
}

.path  .link {
  color: #dc6159;
}

.content {
  margin: 38px 0px;
  font-size: 14px;
  line-height: 32px;
  /* or 229% */
  letter-spacing: 0.05em;
  height: fit-content;
}

.content > div {
    margin: 30px 0px;
}

